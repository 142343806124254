<template>
  <div class="ac2" style="width: 100%">
    <div class="mxw-container">
      <!-- 循环项 -->
      <div class="item">
        <div class="item-img">
          <!-- <img src="/imgs/loding.gif" alt=""> -->
          <video src="/imgs/1.mp4" ></video>
        </div>
        <p>小区摄像头1</p>

      </div>

        <!-- 循环项 -->
        <div class="item">
        <div class="item-img">
          <!-- <img src="/imgs/loding.gif" alt=""> -->
          <video src="/imgs/1.mp4" ></video>
        </div>
        <p>小区摄像头2</p>

      </div>

        <!-- 循环项 -->
        <div class="item">
        <div class="item-img">
          <!-- <img src="/imgs/loding.gif" alt=""> -->
          <video src="/imgs/1.mp4" ></video>
        </div>
        <p>小区摄像头3</p>

      </div>

        <!-- 循环项 -->
        <div class="item">
        <div class="item-img">
          <!-- <img src="/imgs/loding.gif" alt=""> -->
          <video src="/imgs/1.mp4" ></video>
        </div>
        <p>小区摄像头4</p>

      </div>
     
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";

export default {
  name: "ac1",
  components: {},
  data() {
    return {
      href: "index.html",
      ac1: [],
      ac2: [],
      ac3: [],
      ac: [],
      myData: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init1();
    document.title = "百融至信";
  },
  methods: {
    init1() {
      this.axios.get("/mock/index2.json").then((res) => {
        const data = res.data;
        // 咨询区块1
        this.myData = data.splice(0, 3);
      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      // console.log('1')
      // alert("资讯每篇2元，点击确定购买");
      // this.showModal = true;

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("资讯每条2元，点击确定登录购买");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
    msub() {
      // alert("支付失败！");
      this.$message.error("支付失败!");
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.ac2 {

  .mxw-container {
    width: 1200px;
    margin: 15px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    // 循环项
    .item {
     width: 45%;
     height: 300px;
      margin: 45px 0;
      cursor: pointer;
     .item-img {
      width: 100%;
      height: 300px;
      text-align: center;
      img {
        height: 100%;
      }

      video {
        width: 100%;
        height: 100%;
      }
      
     }

     p {
        width: 100%;
        height: 35px;
        background-color: #18bc9c;
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>
