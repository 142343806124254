<template>
  <div class="a1">
    <!-- 左侧 -->
    <div class="l">
      <a href="/"><img src="./../../public/imgs/b1.jpg" alt="" /></a>
    </div>
    <!-- 右侧 -->
    <div class="r">
      <a href="/"> <img src="./../../public/imgs/b2.jpg" alt="" /></a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.a1 {
  width: 100%;
  height: 350px;
  // background: pink;
  margin: 30px 0;
  @include flex();
  .l {
    width: 75%;
    height: 100%;
    // background-color: #c60023;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .r {
    width: 25%;
    height: 100%;
    margin-left: 30px;
    // background-color: #ff6600;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>