<template>
  <div>
    <nav-header></nav-header>
    <router-view></router-view>
    <nav-footer
      :phone="this.phone"
      :name="this.name1"
      :addr="this.addr"
      :icp="this.icp"
    ></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
import NavHeader from "./../components/NavHeader";
export default {
  name: "home",
  components: {
    NavFooter,
    NavHeader,
  },
  methods() {
    document.title = "识君互娱";
  },
  data() {
    return {
      // email: '邮箱：sunchao@spark.art ',
      phone: "电话：13943093588",
      icp: "备案号：京ICP备20018835号-2 ",
      name1: "北京清北学有方教育科技有限公司",
      addr: "地址：北京市海淀区北三环西路43号一区220号平房",
    };
  },
};
</script>
<style lang='' scoped>
</style>